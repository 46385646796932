<template>
  <div class="home">
    <v-card tile :img="item.bg_picture" class="desktop-view">
      <div class="mask">
        <div class="wrap d-flex justify-center align-end">
          <div class="width100">
            <div class="d-flex justify-space-between align-end white--text text-container">
              <div class="info-block">
                <div class="font-weight-medium text-size">iMDb rating</div>
                <div>
                  <img height="20" src="@/assets/icon/star.svg" alt="star" />
                  <span class="f28" v-if="item.imdb_rating"> {{ item.imdb_rating.toFixed(1) }}</span>
                  /10
                </div>
                <div class="text-wrap mt-4 mb-3">
                  <span class="f40 pr-2 font-krona">{{ item.title }}</span>
                  <span class="f18">({{ item.release_year }})</span>
                </div>
                <div class="mb-3">{{ item.description }}</div>
                <div class="mb-5 d-flex align-center">
                  <div class="d-flex flex-wrap" style="max-width: 350px">
                    <div v-for="(i, c) in item.genres" :key="i.id" class="font-weight-medium text-no-wrap">
                      {{ i.title }}<span v-if="c != item.genres.length - 1" class="pr-1">,</span>
                    </div>
                  </div>
                  <v-divider style="height: 10px" vertical class="white mx-5 my-auto"></v-divider>
                  <div>{{ item.mpa_rating }}</div>
                  <v-divider style="height: 10px" vertical class="white mx-5 my-auto"></v-divider>
                  <div>{{ time(item.duration) }}</div>
                </div>
                <v-btn depressed color="error" height="42" width="160"><v-icon class="pr-3">mdi-play</v-icon> Watch trailer</v-btn>
              </div>
              <div class="block-name mb-12">
                <div>
                  Director:
                  <span v-for="(i, c) in item.directors" :key="i.id" class="font-weight-medium">
                    <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
                  >
                </div>
                <div>
                  Writers:
                  <span v-for="(i, c) in item.writers" :key="i.id" class="font-weight-medium">
                    <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
                  >
                </div>
                <div>
                  Stars:
                  <span v-for="(i, c) in item.stars" :key="i.id" class="font-weight-medium">
                    <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
                  >
                </div>
              </div>
            </div>
            <v-sheet class="mx-auto" max-width="1150" color="transparent">
              <v-slide-group v-model="model" class="px-4" style="height: 310px" show-arrows>
                <template v-slot:prev="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon class="btn-slider" height="34" width="34">
                    <img src="@/assets/icon/prev.svg" alt="prev" />
                  </v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon class="btn-slider" height="34" width="34">
                    <img src="@/assets/icon/next.svg" alt="next" />
                  </v-btn>
                </template>
                <v-slide-item v-for="i in array" :key="i.id" v-slot="{ active, toggle }">
                  <v-card
                    tile
                    flat
                    :class="active ? 'py-1' : 'pt-9'"
                    class="white--text px-3"
                    :width="active ? 171 : 144"
                    color="transparent"
                  >
                    <v-card
                      @click="toggle"
                      @mouseup="getItem(i)"
                      flat
                      :img="i.poster"
                      :height="active ? 225 : 170"
                      width="100%"
                      class="item"
                    ></v-card>
                    <div class="text-caption pt-2">
                      <img src="@/assets/icon/star.svg" alt="star" />
                      <span class="text-body-2 font-weight-medium"> {{ i.imdb_rating.toFixed(1) }}</span>
                      /10
                    </div>
                    <div class="text-wrap text-size font-weight-medium">{{ i.title }}</div>
                  </v-card>
                </v-slide-item>
                <v-slide-item>
                  <v-card :class="data.total != array.length ? '' : 'd-none'" tile flat class="px-3 pt-9" width="144" color="transparent">
                    <v-card
                      tile
                      flat
                      height="170"
                      width="100%"
                      class="white--text d-flex justify-center align-center"
                      color="transparent"
                      @click="moreCard"
                    >
                      <div class="text-center">
                        <v-icon color="white">mdi-plus</v-icon>
                        <div>View more</div>
                      </div>
                    </v-card>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-card>
    <div class="tablet-view pt-10">
      <v-card
        :height="'calc(100vh - 40px)'"
        tile
        flat
        width="366"
        class="d-flex flex-wrap overflow-y-auto pl-4 mr-4"
        color="transparent"
        style="position: fixed"
      >
        <v-card v-for="i in array" :key="i.id" tile flat class="white--text px-3 pb-2" width="174" color="transparent">
          <v-card @click="getItem(i)" tile flat :img="i.poster" height="215" width="100%"></v-card>
          <div class="text-body-2 pt-2">
            <img height="16" src="@/assets/icon/star.svg" alt="star" />
            <span class="text-body-1 font-weight-medium"> {{ i.imdb_rating.toFixed(1) }}</span>
            /10
          </div>
          <div class="text-wrap clip2 font-weight-medium">{{ i.title }}</div>
        </v-card>
        <v-card :class="data.total != array.length ? '' : 'd-none'" tile flat class="px-3 pb-2" width="174" color="transparent">
          <v-card
            tile
            flat
            height="215"
            width="100%"
            class="white--text d-flex justify-center align-center"
            color="transparent"
            @click="moreCard"
          >
            <div class="text-center">
              <v-icon color="white">mdi-plus</v-icon>
              <div>View more</div>
            </div>
          </v-card>
        </v-card>
      </v-card>
      <div class="ml-auto pl-4 tablet-item white--text" style="max-width: calc(100% - 366px)">
        <v-img cover :src="item.bg_picture" max-height="348">
          <div class="mask"></div>
        </v-img>
        <div class="d-flex flex-wrap justify-space-between align-end mb-7">
          <div style="max-width: 500px" class="text-break">
            <span class="f40 pr-2 font-krona">{{ item.title }}</span>
            <span class="f18">({{ item.release_year }})</span>
          </div>
          <v-btn depressed color="error" height="48" width="180"><v-icon class="pr-3">mdi-play</v-icon> Watch trailer</v-btn>
        </div>
        <div class="d-flex flex-wrap justify-space-between mb-8">
          <div style="max-width: 512px">
            <div class="mb-4">{{ item.description }}</div>
            <div class="d-flex flex-wrap align-center mb-4">
              <div class="d-flex flex-wrap" style="max-width: 300px">
                <div v-for="(i, c) in item.genres" :key="i.id" class="font-weight-medium text-no-wrap">
                  {{ i.title }}<span v-if="c != item.genres.length - 1" class="pr-1">,</span>
                </div>
              </div>
              <div class="d-flex">
                <v-divider style="height: 10px" vertical class="white my-auto mx-5"></v-divider>
                <div>{{ item.mpa_rating }}</div>
                <v-divider style="height: 10px" vertical class="white my-auto mx-5"></v-divider>
              </div>
              <div>{{ time(item.duration) }}</div>
            </div>
          </div>
          <div>
            <div class="font-weight-medium">iMDb rating</div>
            <div>
              <img height="20" src="@/assets/icon/star.svg" alt="star" />
              <span class="f28" v-if="item.imdb_rating"> {{ item.imdb_rating.toFixed(1) }}</span>
              /10
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div>
            Director:
            <span v-for="(i, c) in item.directors" :key="i.id" class="font-weight-medium">
              <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
            >
          </div>
          <div>
            Writers:
            <span v-for="(i, c) in item.writers" :key="i.id" class="font-weight-medium">
              <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
            >
          </div>
          <div>
            Stars:
            <span v-for="(i, c) in item.stars" :key="i.id" class="font-weight-medium">
              <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-view">
      <v-card tile flat class="d-flex justify-center flex-wrap overflow-y-auto mobile-list" color="transparent">
        <v-card v-for="i in array" :key="i.id" tile flat class="white--text px-3 pb-5" width="174" color="transparent">
          <router-link :to="`/movie/${i.id}`">
            <v-card tile flat :img="i.poster" height="215" width="100%"></v-card>
          </router-link>
          <div class="text-body-2 pt-2">
            <img height="16" src="@/assets/icon/star.svg" alt="star" />
            <span class="text-body-1 font-weight-medium"> {{ i.imdb_rating.toFixed(1) }}</span>
            /10
          </div>
          <div class="text-wrap clip2 f18 font-weight-medium">{{ i.title }}</div>
        </v-card>
        <v-card v-if="data.total != array.length" tile flat class="px-3 pb-5" width="174" color="transparent">
          <v-card
            @click="moreCard"
            tile
            flat
            height="215"
            width="100%"
            class="white--text d-flex justify-center align-center"
            color="transparent"
          >
            <div class="text-center">
              <v-icon color="white">mdi-plus</v-icon>
              <div>View more</div>
            </div>
          </v-card>
        </v-card>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      model: null,
      item: {},
      array: [],
      page: +this.$route.query.page || 1,
      search: this.$route.query.src || '',
      genre: this.$route.query.genre || 'all',
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const data = new Object();
      data.genre = this.genre;
      data.search = this.search;
      data.page = this.page;
      await this.$store.dispatch('getMoviesList', data);
    },
    getItem(i) {
      this.item = Object.assign({}, i);
    },
    time(time) {
      if (time < 60) {
        return `${time} m`;
      } else {
        return `${Math.floor(time / 60)} h ${time % 60} m`;
      }
    },
    moreCard() {
      this.page++;
      setTimeout(() => {
        this.getData();
      }, 0);
    },
  },
  watch: {
    data(val) {
      if (val) {
        for (let i = 0; i < this.data.results.length; i++) {
          this.array.push(this.data.results[i]);
        }
        this.$nextTick(() => {
          this.page != 1 ? (this.model = null) : (this.model = 1), this.getItem(this.array[0]);
        });
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.moviesList;
    },
  },
  destroyed() {
    this.$store.dispatch('setMoviesList', { results: [] });
  },
};
</script>

<style scoped>
.tablet-view {
  display: none;
}
.mobile-view {
  display: none;
}
.mask {
  background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.89) 67.19%, #161616 100%);
}
.wrap {
  min-height: 100vh;
}
.text-container {
  margin-bottom: 68px;
}
.info-block {
  max-width: 560px;
  width: 100%;
}
.block-name {
  max-width: 320px;
  width: 100%;
}
.item {
  border-radius: 2px !important;
}
.v-card {
  transition: 0.3s all;
}
.btn-slider {
  color: white !important;
  background: rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(94px) !important;
  position: relative;
  top: -30px;
}
.test {
  transform: scaleX(1.225) scaleY(1.3235);
  z-index: 999;
}
@media screen and (max-width: 1152px) {
  .text-container {
    margin-bottom: 52px;
  }
  .text-size {
    font-size: 14px;
  }
}
@media screen and (max-width: 1133px) {
  .desktop-view {
    display: none;
  }
  .tablet-view {
    display: flex;
  }
  .tablet-item {
    padding-right: 30px;
  }
  .mask {
    background: linear-gradient(180deg, rgba(22, 22, 22, 0) 47.99%, #161616 100%);
    height: 100%;
  }
}
@media screen and (max-width: 850px) {
  .tablet-item {
    padding-right: 16px;
  }
}
@media screen and (max-width: 744px) {
  .tablet-view {
    display: none;
  }
  .mobile-view {
    display: block;
    padding-top: 90px;
  }
  .mobile-list {
    padding: 0 18px;
  }
}
</style>
